import clsx from "clsx";

import { Image } from "@/components/displays";

import styles from "./HealthEssentialsSection.module.scss";

export function HealthEssentialsSection(): React.ReactNode {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 text__center">
          <h2 className="text__xl text__bold mg__bottom__l">
            からだに必要なもの
            <br />
            全部入り
            <sup className="sup">※1</sup>
          </h2>
          <p className="text__m">
            <span className="wsnr">
              BASE FOOD
              <sup className="sup">
                <i className="far fa-registered" />
              </sup>
              には、
            </span>
            <span className="text__yellow__dark text__bold">
              <span className="wsnr">26種のビタミンやミネラル、</span>
              <span className="wsnr">たんぱく質、</span>
              <span className="wsnr">食物繊維など、</span>
            </span>
            <br />
            <span className="wsnr">
              からだに必要な栄養素
              <sup className="sup">※1</sup>が
            </span>
            <span className="wsnr">ぎゅっと詰まっています。</span>
          </p>
        </div>
        <div className="col-12 col-m-8 col-offset-m-2 mg__top__l">
          <div className={clsx("bg__white", styles.graphBlock)}>
            <h3 className="text__l text__bold text__center mg__bottom__s">
              BASE BREAD
              <sup className="sup">
                <i className="far fa-registered" />
              </sup>
              <br className="clear__pc" />
              1食(2袋)の栄養素
            </h3>
            <p className="text__s text__center">
              1食あたりの推奨摂取量との各栄養素比較
              <sup className="sup">※</sup>
            </p>
            <Image
              src="https://asset.basefood.co.jp/images/graph/graph_bb_chocolate_v1_12_h.png"
              alt="1食あたりの推奨摂取量との各栄養素比較"
              aspectRatio="2 / 1"
              containerClassName={clsx(
                styles.image,
                styles.fullImages,
                "venn",
                "mg__bottom__m",
                "clear__sp"
              )}
            />
            <Image
              src="https://asset.basefood.co.jp/images/graph/graph_bb_chocolate_v1_12_v.png"
              alt="1食あたりの推奨摂取量との各栄養素比較"
              aspectRatio="49 / 109"
              containerClassName={clsx(styles.image, styles.fullImages, "clear__pc", "mg__top__m")}
            />
            <p className="text__s text__gray__dark">
              ※推定値
              ※栄養素等表示基準値（18才以上、基準熱量2,200kcal）に基づき、1日分の基準値の1/3を100とした場合
              ※BASE BREAD ︎チョコレートの場合
            </p>
          </div>
          <p className="text__s text__gray__dark mg__top__l">
            ※1 1食(BASE BREAD
            <sup className="sup">
              <i className="far fa-registered fa-fw" />
            </sup>
            は2袋、BASE Cookies
            <sup className="sup">
              <i className="far fa-registered fa-fw" />
            </sup>
            は4袋)で、栄養素等表示基準値に基づき、他の食事で過剰摂取が懸念される、脂質・飽和脂肪酸・炭水化物・ナトリウムを除いて、すべての栄養素で1日分の基準値の1/3以上を含む。
          </p>
        </div>
      </div>
    </div>
  );
}
