"use client";

import { useRef } from "react";

import clsx from "clsx";
import { Swiper as SwiperClass } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import { Image } from "@/components/displays";

import styles from "./MerchandiseSwiperSection.module.scss";

export function MerchandiseSwiperSection(): React.ReactNode {
  const swiperRef = useRef<SwiperClass | null>(null);

  const swiperOption = {
    loop: true,
    slidesPerView: 1,
    spaceBetween: "8%",
    initialSlide: 0,
    speed: 1000,
    centeredSlides: true,
  };

  return (
    <Swiper
      {...swiperOption}
      className={clsx(styles.contentsSlider, styles.swiperOption)}
      onSwiper={(swiper) => {
        swiperRef.current = swiper;
      }}
    >
      <SwiperSlide>
        <Image
          src="https://asset.basefood.co.jp/lp/form_04/type_chocolate_v1_12.png"
          alt="BASE BREAD チョコレート"
          aspectRatio="35 / 48"
          containerClassName={styles.image}
        />
      </SwiperSlide>
      <SwiperSlide>
        <Image
          src="https://asset.basefood.co.jp/lp/form_04/type_maple_v1_6.png"
          alt="BASE BREAD メープル"
          aspectRatio="35 / 48"
          containerClassName={styles.image}
        />
      </SwiperSlide>
      <SwiperSlide>
        <Image
          src="https://asset.basefood.co.jp/lp/form_04/type_cinnamon_v1_6.png"
          alt="BASE BREAD シナモン"
          aspectRatio="35 / 48"
          containerClassName={styles.image}
        />
      </SwiperSlide>
      <SwiperSlide>
        <Image
          src="https://asset.basefood.co.jp/lp/form_04/type_koshian_v1_1.png"
          alt="BASE BREAD こしあん"
          aspectRatio="35 / 48"
          containerClassName={styles.image}
        />
      </SwiperSlide>
      <SwiperSlide>
        <Image
          src="https://asset.basefood.co.jp/lp/form_04/type_slice_raisin_v1_3.png"
          alt="BASE BREAD ミニ食パン・レーズン"
          aspectRatio="35 / 48"
          containerClassName={styles.image}
        />
      </SwiperSlide>
      <SwiperSlide>
        <Image
          src="https://asset.basefood.co.jp/lp/form_04/type_rich_v1_4.png"
          alt="BASE BREAD リッチ"
          aspectRatio="35 / 48"
          containerClassName={styles.image}
        />
      </SwiperSlide>
      <SwiperSlide>
        <Image
          src="https://asset.basefood.co.jp/lp/form_04/type_curry_v1_6.png"
          alt="BASE BREAD カレー"
          aspectRatio="35 / 48"
          containerClassName={styles.image}
        />
      </SwiperSlide>
      <SwiperSlide>
        <Image
          src="https://asset.basefood.co.jp/lp/form_04/type_plain_v3_11.png"
          alt="BASE BREAD プレーン"
          aspectRatio="35 / 48"
          containerClassName={styles.image}
        />
      </SwiperSlide>
      <SwiperSlide>
        <Image
          src="https://asset.basefood.co.jp/lp/form_04/type_slice_plain_v1_5_0821.png"
          alt="BASE BREAD ミニ食パン・プレーン"
          aspectRatio="35 / 48"
          containerClassName={styles.image}
        />
      </SwiperSlide>
      <div className={styles.contentsNavNext} onClick={() => swiperRef.current?.slideNext()} />
      <div className={styles.contentsNavPrev} onClick={() => swiperRef.current?.slidePrev()} />
    </Swiper>
  );
}
