import clsx from "clsx";

import styles from "./IdealNutritionBalanceSection.module.scss";

export function IdealNutritionBalanceSection(): React.ReactNode {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 text__center mg__bottom__m">
          <h2 className="text__xl text__bold">理想的な栄養バランス</h2>
        </div>
      </div>
      <div className="row mg__top__m">
        <div className="col-12 col-m-4 col-offset-m-2">
          <div className={clsx(styles.idealNutritionalBlock, "mg__bottom__m", "bg__white")}>
            <p className="text__l text__yellow text__bold mg__bottom__s text__center">
              高たんぱく質
            </p>
            <p>主に植物由来のたんぱく質が約30g含まれています。</p>
          </div>
        </div>
        <div className="col-12 col-m-4">
          <div className={clsx(styles.idealNutritionalBlock, "mg__bottom__m", "bg__white")}>
            <p className="text__l text__yellow text__bold mg__bottom__s text__center">
              食物繊維たっぷり
            </p>
            <p>全粒粉など穀物や大豆由来の食物繊維が約8g含まれています。</p>
          </div>
        </div>
        <div className="col-12 col-m-4 col-offset-m-2">
          <div className={clsx(styles.idealNutritionalBlock, "mg__bottom__m", "bg__white")}>
            <p className="text__l text__yellow text__bold mg__bottom__s text__center">
              26種のビタミン＆ミネラル
            </p>
            <p>からだに必要な26種のビタミンとミネラルをすべてカバー。</p>
          </div>
        </div>
        <div className="col-12 col-m-4">
          <div className={clsx(styles.idealNutritionalBlock, "mg__bottom__m", "bg__white")}>
            <p className="text__l text__yellow text__bold mg__bottom__s text__center">必須脂肪酸</p>
            <p>オメガ3脂肪酸が約1g含まれています。</p>
          </div>
        </div>
        <div className="col-12 col-m-4 col-offset-m-2">
          <div className={clsx(styles.idealNutritionalBlock, "mg__bottom__m", "bg__white")}>
            <p className="text__l text__yellow text__bold mg__bottom__s text__center">糖質オフ</p>
            <p>
              一般的なパンと比べて、気になる糖質は約30%オフ<sup className="sup">※</sup>。
            </p>
            <p className="text__s mg__top__s text__gray__dark">
              ※BASE BREAD
              <sup className="sup">
                <i className="far fa-registered fa-fw" />
              </sup>
              チョコレートと、日本食品標準成分表2020年版（八訂）より、菓子パン
              あんなしとの比較（同量）
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text__center">
          <div className="mg__top__l text__center">
            <p className="text__justify text__center__pc text__s text__gray__dark">
              ・上記の栄養素は、BASE BREAD
              <sup className="sup">
                <i className="far fa-registered fa-fw" />
              </sup>
              1袋の場合です。
              <br />
              ・BASE BREAD
              <sup className="sup">
                <i className="far fa-registered fa-fw" />
              </sup>
              に含まれる栄養素に関しては、
              <a
                href="https://basefood.zendesk.com/hc/ja/articles/360029467171"
                target="_blank"
                className="text__bold"
                rel="noopener noreferrer"
              >
                こちら
              </a>
              をご覧ください。
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
