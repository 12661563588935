"use client";

import { useRef, useState } from "react";

import clsx from "clsx";
import Link from "next/link";
import { Swiper as SwiperClass } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { Image } from "@/components/displays";
import {
  SafetyBanner,
  Variation,
  CvHead,
  SetDetail,
  CvButton,
  Privilege,
  Notes,
  Ingredients,
  OnePageCheckoutForm,
  ScrollButton,
} from "@/components/domains/lp/";
import { ProductModel } from "@/models/product/type";

import "swiper/css";

import styles from "./Diet04.module.scss";
import { useScrollObserver } from "./util";

interface Diet4Props {
  products: ProductModel[];
}

export function Diet04({ products }: Diet4Props): React.ReactNode {
  const swiperRef = useRef<SwiperClass | null>(null);
  const [isQuestion1, setIsQuestion1] = useState(false);
  const [isQuestion2, setIsQuestion2] = useState(false);
  const [isQuestion3, setIsQuestion3] = useState(false);
  const [isQuestion4, setIsQuestion4] = useState(false);

  const swiperOption = {
    loop: true,
    slidesPerView: 1,
    spaceBetween: "8%",
    initialSlide: 0,
    speed: 1000,
    centeredSlides: true,
  };

  useScrollObserver(".animationObserver", {
    topVal: 140,
    bottomVal: -20,
    thresholdVal: 0.5,
    windowVal: 0.5,
    repeat: true,
    Start: (target) => {
      target.classList.add(styles.jsObserve);
    },
    End: (target) => {
      target.classList.remove(styles.jsObserve);
    },
  });

  return (
    <div className={styles.lpDiet}>
      <article className={styles.llw}>
        <div className={clsx(styles.overlayArea)}>
          <div className={styles.overlayArea}>
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp01_01-02.jpg"
              alt="1日1食置き換えダイエットならBASE BREAD"
              aspectRatio="750 / 1379"
              containerClassName={styles.image}
            />
            <div className={clsx(styles.overlayItem, styles.lpElement01, styles.lpElement01__01)}>
              <Image
                src="https://asset.basefood.co.jp/lp/diet_04/lp01_01-02_elm_01.png"
                alt="たんぱく質約30g"
                size={{ width: 182, height: 192 }}
                containerClassName={styles.image}
              />
            </div>
            <div className={clsx(styles.overlayItem, styles.lpElement01, styles.lpElement01__02)}>
              <Image
                src="https://asset.basefood.co.jp/lp/diet_04/0805_lp01_01-02_elm_02.png"
                alt="糖質約30%OFF"
                size={{ width: 182, height: 192 }}
                containerClassName={styles.image}
              />
            </div>
            <div className={clsx(styles.overlayItem, styles.lpElement01, styles.lpElement01__03)}>
              <Image
                src="https://asset.basefood.co.jp/lp/diet_04/lp01_01-02_elm_03.png"
                alt="26種のビタミン・ミネラル"
                size={{ width: 182, height: 192 }}
                containerClassName={styles.image}
              />
            </div>
          </div>
          <Image
            src="https://asset.basefood.co.jp/lp/diet_04/0805_lp01_02.jpg"
            alt="ダイエットには適度な運動と食事制限が必要です"
            aspectRatio="750 / 253"
            containerClassName={styles.image}
          />
          <SafetyBanner lpType="diet" />
          <Image
            src="https://asset.basefood.co.jp/lp/diet_04/lp02_01.jpg"
            alt="“パン”だからおいしく無理なく続けられる！"
            aspectRatio="750 / 206"
            containerClassName={styles.image}
          />
          <CvHead />
          <div className="gothicCta">
            <SetDetail bags={14} />
            <CvButton width="narrow" bags={14} />
          </div>
          <div className="gothicCta">
            <SetDetail bags={32} />
            <CvButton width="narrow" bags={32} />
          </div>
          <div className="gothicCta">
            <SetDetail bags={54} />
            <CvButton width="narrow" bags={54} />
          </div>
          <div>
            <Privilege />
            <Notes />
          </div>
          <Image
            src="https://asset.basefood.co.jp/lp/diet_04/cta01_05.png"
            alt="3大特典"
            aspectRatio="375 / 584"
            containerClassName={styles.image}
          />

          <div className="floating">
            <div className={styles.overlayArea} id="tvcm">
              <Image
                src="https://asset.basefood.co.jp/lp/diet_04/lp02_03.jpg"
                alt="TVCM放送中！"
                aspectRatio="750 / 697"
                containerClassName={styles.image}
              />
              <div className={clsx(styles.overlayItem, styles.youtube01Inner)}>
                <div className={styles.youtube01Container}>
                  <div id="youtubeVideo01" className={styles.youtube01Item}>
                    <div className={styles.videoResponsive}>
                      <iframe
                        width="1280"
                        height="720"
                        src="https://www.youtube.com/embed/zVCrWHEg6wo?autoplay=1&mute=1&loop=1&playlist=zVCrWHEg6wo"
                        title="BASE FOOD TV CM「ベースフード おきかえ 自宅に届く」編（15秒）"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp02_04.jpg"
              alt="完全栄養食で「食べるダイエット」という選択"
              aspectRatio="375 / 413"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/0805_lp02_05.png"
              alt="普段のコンビニランチを置き換えて栄養満点でカロリーもセーブ！"
              aspectRatio="375 / 373"
              containerClassName={styles.image}
            />

            <div className={clsx(styles.overlayArea)}>
              <Image
                src="https://asset.basefood.co.jp/lp/diet_04/lp02_06.jpg"
                alt="※栄養素等表示基準に基づき、1日分の基準値の1/3を100とした場合"
                aspectRatio="375 / 373"
                containerClassName={styles.image}
              />
              <picture className={clsx(styles.overlayItem, styles.gif01Item__01)}>
                <Image
                  src="https://asset.basefood.co.jp/lp/diet_04/lp02_06_gif_01.jpg"
                  alt="コンビニランチとBASE BREADの栄養素の比較グラフ"
                  aspectRatio="677 / 639"
                  containerClassName={styles.image}
                />
              </picture>
            </div>

            <div className={clsx(styles.overlayArea)}>
              <Image
                src="https://asset.basefood.co.jp/lp/diet_04/0805_lp02_07.png"
                alt="コンビニランチ BASE BREAD"
                aspectRatio="375 / 373"
                containerClassName={styles.image}
              />
              <picture
                className={clsx(
                  styles.overlayItem,
                  styles.lpElement02,
                  styles.lpElement02__01,
                  "animationObserver"
                )}
              >
                <Image
                  src="https://asset.basefood.co.jp/lp/diet_04/lp02_07_elm_01.png"
                  alt="354kcalカット！"
                  aspectRatio="713 / 194"
                  containerClassName={styles.image}
                />
              </picture>
            </div>

            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp03_01.jpg"
              alt="トップアスリートにも選ばれてます！"
              aspectRatio="375 / 217"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp03_02.jpg"
              alt="総合格闘家 杉山しずかさん 格闘家の減量食としても、徐々に浸透しているBASE FOOD"
              aspectRatio="375 / 557"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp03_03.jpg"
              alt="ベースブレッド愛用者 クマガミさん 3ヵ月で理想のボディに！"
              aspectRatio="375 / 559"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp03_04.jpg"
              alt="SNSでも人気沸騰中"
              aspectRatio="25 / 12"
              containerClassName={styles.image}
            />

            <div className={styles.overlayArea}>
              <Image
                src="https://asset.basefood.co.jp/lp/diet_04/lp03_05.jpg"
                alt="おいしい！ 食べやすい！ 腹持ちがいい！"
                aspectRatio="375 / 517"
                containerClassName={styles.image}
              />
              <div className={clsx(styles.lpElement03__Container, styles.overlayItem)}>
                <picture
                  className={clsx(
                    styles.overlayItem,
                    styles.lpElement03,
                    styles.lpElement03__01,
                    "animationObserver"
                  )}
                >
                  <Image
                    src="https://asset.basefood.co.jp/lp/diet_04/lp03_05_elm_01.png"
                    alt="おいしい！ "
                    aspectRatio="271 / 119"
                    containerClassName={styles.image}
                  />
                </picture>
                <picture
                  className={clsx(
                    styles.overlayItem,
                    styles.lpElement03,
                    styles.lpElement03__02,
                    "animationObserver"
                  )}
                >
                  <Image
                    src="https://asset.basefood.co.jp/lp/diet_04/lp03_05_elm_02.png"
                    alt="食べやすい！ "
                    aspectRatio="271 / 119"
                    containerClassName={styles.image}
                  />
                </picture>
                <picture
                  className={clsx(
                    styles.overlayItem,
                    styles.lpElement03,
                    styles.lpElement03__03,
                    "animationObserver"
                  )}
                >
                  <Image
                    src="https://asset.basefood.co.jp/lp/diet_04/lp03_05_elm_03.png"
                    alt="食べやすい！"
                    aspectRatio="271 / 119"
                    containerClassName={styles.image}
                  />
                </picture>
              </div>
            </div>

            <div className={clsx(styles.overlayArea)}>
              <Image
                src="https://asset.basefood.co.jp/lp/diet_04/lp03_06.jpg"
                alt="美味しくて栄養満点！"
                aspectRatio="750 / 1102"
                containerClassName={styles.image}
              />
              <svg
                className={clsx(styles.circle01, styles.overlayItem, "animationObserver")}
                viewBox="0 0 100 100"
              >
                <circle className={styles.circle01Item__01} cx="50" cy="50" r="45" />
              </svg>
              <picture
                className={clsx(styles.overlayItem, styles.circle01Item__02, "animationObserver")}
              >
                <Image
                  src="https://asset.basefood.co.jp/lp/diet_04/lp03_06_elm_01.png"
                  alt="89.6%！"
                  aspectRatio="475 / 226"
                  containerClassName={styles.image}
                />
              </picture>
              <picture className={clsx(styles.overlayItem, styles.circle01Item__03)}>
                <Image
                  src="https://asset.basefood.co.jp/lp/diet_04/0805_lp03_06_elm_02_chocolate_v1_12.png"
                  alt="BASE BREAD チョコレート"
                  aspectRatio="441 / 327"
                  containerClassName={styles.image}
                />
              </picture>
            </div>
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp03_07.jpg"
              alt="Amazon売れ筋ランキング 第1位獲得"
              aspectRatio="375 / 491"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp03_08.jpg"
              alt="POP UP CAFEも開催"
              aspectRatio="250 / 127"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp03_09.jpg"
              alt="多くの方から反響をいただきました！"
              aspectRatio="30 / 37"
              containerClassName={styles.image}
            />
          </div>

          <CvHead />

          <div className="gothicCta">
            <SetDetail bags={14} />
            <CvButton width="narrow" bags={14} />
          </div>
          <div className="gothicCta">
            <SetDetail bags={32} />
            <CvButton width="narrow" bags={32} />
          </div>
          <div className="gothicCta">
            <SetDetail bags={54} />
            <CvButton width="narrow" bags={54} />
          </div>

          <div>
            <Privilege />
            <Notes />
          </div>

          <Image
            src="https://asset.basefood.co.jp/lp/diet_04/cta01_05.png"
            alt="3大特典"
            aspectRatio="375 / 584"
            containerClassName={styles.image}
          />
          <Image
            src="https://asset.basefood.co.jp/lp/diet_04/lp04_01.jpg"
            alt="多くの人が陥りがちなダイエットの落とし穴…たんぱく質不足"
            aspectRatio="75 / 32"
            containerClassName={styles.image}
          />

          <div className="floating">
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp04_02.jpg"
              alt="最近の食事こんな風になっていませんか？"
              aspectRatio="375 / 482"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp04_03.jpg"
              alt="1日のたんぱく質推奨摂取量 成人男性65g 成人女性50g"
              aspectRatio="125 / 111"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp04_04.jpg"
              alt="なんと納豆10パック分相当！"
              aspectRatio="375 / 578"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp04_05.jpg"
              alt="でも…正直、継続がムズカシイ…！"
              aspectRatio="375 / 323"
              containerClassName={styles.image}
            />
            <div className={styles.overlayArea}>
              <Image
                src="https://asset.basefood.co.jp/lp/diet_04/lp04_06.png"
                alt="そこで！現代の置き換えダイエットの新定番BASE BREAD"
                aspectRatio="125 / 137"
                containerClassName={styles.image}
              />
              <div className={clsx(styles.lpElement04__Container, styles.overlayItem)}>
                <picture
                  className={clsx(
                    styles.overlayItem,
                    styles.lpElement04,
                    styles.lpElement04__01,
                    "animationObserver"
                  )}
                >
                  <Image
                    src="https://asset.basefood.co.jp/lp/diet_04/lp04_06_elm_01.png"
                    alt=""
                    aspectRatio="47 / 44"
                    containerClassName={styles.image}
                  />
                </picture>
                <picture
                  className={clsx(
                    styles.overlayItem,
                    styles.lpElement04,
                    styles.lpElement04__02,
                    "animationObserver"
                  )}
                >
                  <Image
                    src="https://asset.basefood.co.jp/lp/diet_04/lp04_06_elm_01.png"
                    alt=""
                    aspectRatio="47 / 44"
                    containerClassName={styles.image}
                  />
                </picture>
                <picture
                  className={clsx(
                    styles.overlayItem,
                    styles.lpElement04,
                    styles.lpElement04__03,
                    "animationObserver"
                  )}
                >
                  <Image
                    src="https://asset.basefood.co.jp/lp/diet_04/lp04_06_elm_01.png"
                    alt=""
                    aspectRatio="47 / 44"
                    containerClassName={styles.image}
                  />
                </picture>
              </div>
            </div>
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/0805_lp04_07.png"
              alt="理想をおいしく叶えるダイエットサポート食"
              aspectRatio="375 / 343"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp04_08.jpg"
              alt="BASE BREADにはたんぱく質が約30g"
              aspectRatio="750 / 749"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/0805_lp04_10.png"
              alt="Point01 33種類の栄養素をギュッと1食に"
              aspectRatio="750 / 931"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp04_11.png"
              alt="1食置き換えるだけで、理想的な栄養バランスに近づく。"
              aspectRatio="125 / 71"
              containerClassName={styles.image}
            />
            <div className={styles.overlayArea}>
              <Image
                src="https://asset.basefood.co.jp/lp/diet_04/lp04_12.jpg"
                alt="1日のうち1食をBASE BREADにするだけで必要な栄養素の1/3を補える置き換えにぴったりな栄養バランス。…"
                aspectRatio="125 / 178"
                containerClassName={styles.image}
              />
              <picture className={clsx(styles.overlayItem, styles.gif03Item__01)}>
                <Image
                  src="https://asset.basefood.co.jp/lp/diet_04/lp04_12_gif_01.gif"
                  alt="BASE BREADに含まれる栄養素"
                  aspectRatio="171 / 178"
                  containerClassName={styles.image}
                />
              </picture>
            </div>
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp04_13.jpg"
              alt="Point02 全粒粉ベースで良質かつ適度な糖質セーブ"
              aspectRatio="75 / 53"
              containerClassName={styles.image}
            />
            <div className={styles.overlayArea}>
              <Image
                src="https://asset.basefood.co.jp/lp/diet_04/lp04_14.png"
                alt="一般的なパンより糖質"
                aspectRatio="750 / 841"
                containerClassName={styles.image}
              />
              <div
                className={clsx(styles.overlayItem, styles.lpElement04_14__01, "animationObserver")}
              />
              <picture className={clsx(styles.overlayItem, styles.lpElement04_14__02)}>
                <Image
                  src="https://asset.basefood.co.jp/lp/diet_04/0805_lp04_14_elm_01.png"
                  alt="約35%オフ"
                  aspectRatio="1187 / 280"
                  containerClassName={styles.image}
                />
              </picture>
            </div>

            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/0805_lp04_15.png"
              alt="Point03 かしこく「時短」さっと取り出し、すぐに食べられます。"
              aspectRatio="125 / 182"
              containerClassName={styles.image}
            />

            <div className={styles.overlayArea}>
              <Image
                src="https://asset.basefood.co.jp/lp/diet_04/lp04_16.jpg"
                alt="自炊にかける時間が以前より短くなった！"
                aspectRatio="375 / 184"
                containerClassName={styles.image}
              />
              <svg
                className={clsx(styles.circle02, styles.overlayItem, "animationObserver")}
                viewBox="0 0 100 100"
              >
                <circle className={styles.circle02Item__01} cx="50" cy="50" r="25" />
              </svg>
              <picture
                className={clsx(styles.overlayItem, styles.lpElement04_16__01, "animationObserver")}
              >
                <Image
                  src="https://asset.basefood.co.jp/lp/diet_04/lp04_16_elm_01.png"
                  alt=""
                  aspectRatio="155 / 129"
                  containerClassName={styles.image}
                />
              </picture>
              <picture
                className={clsx(styles.overlayItem, styles.lpElement04_16__02, "animationObserver")}
              >
                <Image
                  src="https://asset.basefood.co.jp/lp/diet_04/lp04_16_elm_02.png"
                  alt=""
                  aspectRatio="226 / 133"
                  containerClassName={styles.image}
                />
              </picture>
            </div>
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/0424_lp04_18.png"
              alt="手軽に健康的にそして理想ボディへ。"
              aspectRatio="375 / 544"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp05_01.jpg"
              alt="こんな時におすすめ"
              aspectRatio="125 / 82"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp05_02.jpg"
              alt="SCENE01 「作ってる時間がない！」忙しい朝に"
              aspectRatio="25 / 18"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp05_03.jpg"
              alt="SCENE02 「そろそろお金も節約したい。」コンビニランチの代わりに"
              aspectRatio="375 / 281"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp05_04.jpg"
              alt="SCENE03 「またこんな時間…」遅い時間の夕飯に"
              aspectRatio="375 / 278"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp05_05.jpg"
              alt="SCENE04 「どうしても食べたい…！」ダイエット中にパンが食べたい時"
              aspectRatio="75 / 56"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp05_06.jpg"
              alt="楽しみ方は無限大！"
              aspectRatio="125 / 71"
              containerClassName={styles.image}
            />
            <div className={styles.overlayArea}>
              <Image
                src="https://asset.basefood.co.jp/lp/diet_04/lp05_07.jpg"
                alt=""
                aspectRatio="375 / 518"
                containerClassName={styles.image}
              />
              <Swiper
                {...swiperOption}
                className={clsx(styles.contentsSlider, styles.overlayItem)}
                onSwiper={(swiper) => {
                  swiperRef.current = swiper;
                }}
              >
                <SwiperSlide>
                  <Image
                    src="https://asset.basefood.co.jp/lp/diet_04/recipe_rich_v1_4.png"
                    alt="01 彩りヘルシーサンド"
                    aspectRatio="75 / 106"
                    containerClassName={styles.image}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Image
                    src="https://asset.basefood.co.jp/lp/diet_04/recipe_chocolate_v1_11.png"
                    alt="02 BREADチョコアレンジレシピ（クリチ&ハチミツ&シナモン）"
                    aspectRatio="75 / 106"
                    containerClassName={styles.image}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Image
                    src="https://asset.basefood.co.jp/lp/diet_04/recipe_maple_v1_5.png"
                    alt="03 マロンパン"
                    aspectRatio="75 / 106"
                    containerClassName={styles.image}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Image
                    src="https://asset.basefood.co.jp/lp/diet_04/recipe_cinnamon_v1_5.png"
                    alt="04 ベースシナモンロール"
                    aspectRatio="75 / 106"
                    containerClassName={styles.image}
                  />
                </SwiperSlide>
                <div
                  className={styles.contentsNavNext}
                  onClick={() => swiperRef.current?.slideNext()}
                />
                <div
                  className={styles.contentsNavPrev}
                  onClick={() => swiperRef.current?.slidePrev()}
                />
              </Swiper>
            </div>

            <div className={styles.overlayArea}>
              <Image
                src="https://asset.basefood.co.jp/lp/diet_04/lp05_08.jpg"
                alt="そのまま食べても香ばしい味わいでおいしく召し上がれます♪"
                aspectRatio="75 / 28"
                containerClassName={styles.image}
              />
              <picture className={(styles.overlayItem, styles.lpElement05__01)}>
                <Image
                  src="https://asset.basefood.co.jp/lp/diet_04/lp05_08_elm_01.png"
                  alt="もちろん"
                  aspectRatio="200 / 129"
                  containerClassName={styles.image}
                />
              </picture>
            </div>
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/0805_lp05_09.png"
              alt="続けるほど、効果を実感。心かるく、ムダのないボリューム感。"
              aspectRatio="25 / 41"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp05_10.jpg"
              alt="医学専門家も推奨 食事は短距離走でなくマラソンです。"
              aspectRatio="250 / 319"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp05_11.jpg"
              alt="白米の食べすぎと塩分のとりすぎは日本人の不健康の二大要因ですが、BASE FOODはからだにいいと証明されている全粒穀物を使用しています。…"
              aspectRatio="750 / 1013"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp05_12.jpg"
              alt="満足感もあってからだにいいことだらけ！"
              aspectRatio="375 / 169"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp05_13.jpg"
              alt="User Voice 01 27歳 M.N様 食事制限中の物足りなさもたっぷりボリュームで気にならない！"
              aspectRatio="25 / 38"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp05_14.jpg"
              alt="User Voice 02 34歳 T.O様 栄養面もコスパも良好！ダイエット時の健康管理のお供です。"
              aspectRatio="125 / 189"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp05_15.jpg"
              alt="他にもたくさんのお声が届いてます"
              aspectRatio="25 / 41"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp06_01.jpg"
              alt="おいしい完全栄養食のためにこだわった厳選素材"
              aspectRatio="375 / 208"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp06_02.jpg"
              alt="栄養バランスを支える土台 ふんわり香ばしい全粒粉 01"
              aspectRatio="375 / 422"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp06_03.jpg"
              alt="たんぱく質やミネラルを補う 栄養価の高い原料 02"
              aspectRatio="375 / 413"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp06_04.jpg"
              alt="味と食感もとことん追求 チアシード入り 03"
              aspectRatio="375 / 502"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp06_05.jpg"
              alt="安心してお召し上がりいただくために国内工場生産"
              aspectRatio="375 / 488"
              containerClassName={styles.image}
            />
            <Variation />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/arrange_recipe_01.jpg"
              alt="飽きなく続けられるように6つの味をお届け"
              aspectRatio="749 / 724"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/arrange_recipe_02.jpg"
              alt="飽きなく続けられるように6つの味をお届け"
              aspectRatio="107 / 135"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/0805_lp06_13.png"
              alt="かしこく手軽に！おいしく理想のワタシへ。"
              aspectRatio="750 / 1291"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp07_01.jpg"
              alt="BASE BREADをまとめて買うなら継続コースが断然お得！"
              aspectRatio="750 / 413"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/0805_lp07_02.png?0731"
              alt="16袋セット内容で買った場合 金額比較"
              aspectRatio="125 / 129"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp07_03_coconut_v1_2.jpg"
              alt="継続コース初回ご購入の方全員にプレゼント！ BASE Cookies ココナッツ"
              aspectRatio="375 / 467"
              containerClassName={styles.image}
            />
            <Image
              src="https://asset.basefood.co.jp/lp/diet_04/lp07_04.jpg"
              alt="さらに継続コース限定マイルプログラム"
              aspectRatio="375 / 364"
              containerClassName={styles.image}
            />
          </div>
          <CvHead />
          <div className="gothicCta">
            <SetDetail bags={14} />
            <CvButton width="narrow" bags={14} />
          </div>
          <div className="gothicCta">
            <SetDetail bags={32} />
            <CvButton width="narrow" bags={32} />
          </div>
          <div className="gothicCta">
            <SetDetail bags={54} />
            <CvButton width="narrow" bags={54} />
          </div>
          <div>
            <Privilege />
            <Notes />
          </div>
          <Image
            src="https://asset.basefood.co.jp/lp/diet_04/cta01_05.png"
            alt="3大特典"
            aspectRatio="375 / 584"
            containerClassName={styles.image}
          />
          <Image
            src="https://asset.basefood.co.jp/lp/diet_04/lp07_06.jpg"
            alt="お届けサイクル"
            aspectRatio="150 / 29"
            containerClassName={styles.image}
          />
          <Image
            src="https://asset.basefood.co.jp/lp/diet_04/cycle/0805_cycle_set12.png"
            alt="12袋セット"
            aspectRatio="750 / 487"
            containerClassName={styles.image}
          />
          <Image
            src="https://asset.basefood.co.jp/lp/diet_04/cycle/0805_cycle_set28.png"
            alt="28袋セット"
            aspectRatio="350 / 244"
            containerClassName={styles.image}
          />
          <Image
            src="https://asset.basefood.co.jp/lp/diet_04/cycle/0805_cycle_set46.png"
            alt="46袋セット"
            aspectRatio="750 / 487"
            containerClassName={styles.image}
          />
          <Image
            src="https://asset.basefood.co.jp/lp/diet_04/lp07_09.jpg"
            alt="どちらのセットもお届け回数縛りなし！"
            aspectRatio="375 / 92"
            containerClassName={styles.image}
          />
          <Image
            src="https://asset.basefood.co.jp/lp/diet_04/lp08_02.jpg"
            alt="よくあるご質問 Q&A"
            aspectRatio="125 / 59"
            containerClassName={styles.image}
          />
          <div
            className={clsx(
              styles.accordionContainer,
              styles.accordionContainer02,
              styles.overlayArea
            )}
          >
            <picture className={styles.overlayItem}>
              <img src="https://asset.basefood.co.jp/lp/diet_04/lp08_02_bg.jpg" alt="" />
            </picture>

            <div className={clsx(styles.accordionItem, styles.accordionItem__01)}>
              <div
                className={styles.accordionBtn}
                onClick={() => {
                  setIsQuestion1(!isQuestion1);
                }}
              >
                <Image
                  src="https://asset.basefood.co.jp/lp/diet_04/lp08_02_accord_q_01.png"
                  alt="Q1.賞味期限はどのくらいですか？"
                  aspectRatio="682 / 97"
                  containerClassName={styles.image}
                />
                <div className={clsx(styles.accordionState, styles.accordionState__01)}>
                  <Image
                    src="https://asset.basefood.co.jp/lp/diet_04/lp08_02_accord_btn.png"
                    alt=""
                    aspectRatio="64 / 69"
                    containerClassName={styles.image}
                  />
                </div>
              </div>
              <div
                className={clsx(
                  styles.accordionContent,
                  { [styles.jsOpen]: isQuestion1 },
                  { [styles.jsClose]: !isQuestion1 }
                )}
              >
                <div>
                  <Image
                    src="https://asset.basefood.co.jp/lp/diet_04/0424_lp08_02_accord_a_01.png"
                    alt="賞味期限は約１ヶ月です。…"
                    aspectRatio="682 / 225"
                    containerClassName={styles.image}
                  />
                </div>
              </div>
            </div>

            <div className={clsx(styles.accordionItem, styles.accordionItem__01)}>
              <div
                className={styles.accordionBtn}
                onClick={() => {
                  setIsQuestion2(!isQuestion2);
                }}
              >
                <Image
                  src="https://asset.basefood.co.jp/lp/diet_04/lp08_02_accord_q_02.png"
                  alt="Q2.継続コースの注文内容は変更できますか？"
                  aspectRatio="341 / 70"
                  containerClassName={styles.image}
                />
                <div className={clsx(styles.accordionState, styles.accordionState__01)}>
                  <Image
                    src="https://asset.basefood.co.jp/lp/diet_04/lp08_02_accord_btn.png"
                    alt=""
                    aspectRatio="64 / 69"
                    containerClassName={styles.image}
                  />
                </div>
              </div>
              <div
                className={clsx(
                  styles.accordionContent,
                  { [styles.jsOpen]: isQuestion2 },
                  { [styles.jsClose]: !isQuestion2 }
                )}
              >
                <div>
                  <Image
                    src="https://asset.basefood.co.jp/lp/diet_04/lp08_02_accord_a_02.png"
                    alt="継続コースの注文内容は、商品の種類に関係なく、自由な組み合わせにご変更いただけます。"
                    aspectRatio="62 / 13"
                    containerClassName={styles.image}
                  />
                </div>
              </div>
            </div>

            <div className={clsx(styles.accordionItem, styles.accordionItem__01)}>
              <div
                className={styles.accordionBtn}
                onClick={() => {
                  setIsQuestion3(!isQuestion3);
                }}
              >
                <Image
                  src="https://asset.basefood.co.jp/lp/diet_04/lp08_02_accord_q_03.png"
                  alt="Q3. BASE BREADは、どう保存したらいいですか？"
                  aspectRatio="341 / 70"
                  containerClassName={styles.image}
                />
                <div className={clsx(styles.accordionState, styles.accordionState__01)}>
                  <Image
                    src="https://asset.basefood.co.jp/lp/diet_04/lp08_02_accord_btn.png"
                    alt=""
                    aspectRatio="64 / 69"
                    containerClassName={styles.image}
                  />
                </div>
              </div>
              <div
                className={clsx(
                  styles.accordionContent,
                  { [styles.jsOpen]: isQuestion3 },
                  { [styles.jsClose]: !isQuestion3 }
                )}
              >
                <div>
                  <Image
                    src="https://asset.basefood.co.jp/lp/diet_04/lp08_02_accord_a_03.png"
                    alt="直射日光、高温多湿をさけ、冷暗所（15〜20度）で保存してください。…"
                    aspectRatio="341 / 150"
                    containerClassName={styles.image}
                  />
                </div>
              </div>
            </div>

            <div className={clsx(styles.accordionItem, styles.accordionItem__01)}>
              <div
                className={styles.accordionBtn}
                onClick={() => {
                  setIsQuestion4(!isQuestion4);
                }}
              >
                <Image
                  src="https://asset.basefood.co.jp/lp/diet_04/lp08_02_accord_q_04.png"
                  alt="Q4.1日3食BASE FOODを食べないといけないですか？"
                  aspectRatio="341 / 70"
                  containerClassName={styles.image}
                />
                <div className={clsx(styles.accordionState, styles.accordionState__01)}>
                  <Image
                    src="https://asset.basefood.co.jp/lp/diet_04/lp08_02_accord_btn.png"
                    alt=""
                    aspectRatio="64 / 69"
                    containerClassName={styles.image}
                  />
                </div>
              </div>
              <div
                className={clsx(
                  styles.accordionContent,
                  { [styles.jsOpen]: isQuestion4 },
                  { [styles.jsClose]: !isQuestion4 }
                )}
              >
                <div>
                  <Image
                    src="https://asset.basefood.co.jp/lp/diet_04/lp08_02_accord_a_04.png"
                    alt="安心してお召しあがりいただける主食なので、毎食食べていただいても大丈夫ですが、1日3食食べないといけないということはございません。…"
                    aspectRatio="682 / 435"
                    containerClassName={styles.image}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <div className={styles.wrapper}>
        <div className="container">
          <div className="row">
            <div className={clsx(styles.onePageWrap, "pd__top__l")}>
              <OnePageCheckoutForm products={products} variant="LP_DIET_02" pageFrom="dietform" />
            </div>
          </div>
        </div>
        <Ingredients />
      </div>
      <div className={styles.wrapper}>
        <div className={styles.footer}>
          <Image
            src="https://asset.basefood.co.jp/lp/diet_03/footer.jpg"
            alt=""
            aspectRatio="250 / 123"
            containerClassName={styles.image}
          />
          <div className={styles.footerInner}>
            <Link href="https://basefood.co.jp" target="_blank" className={styles.linkContents}>
              <Image
                src="https://asset.basefood.co.jp/lp/diet_03/footer_btn_1.png"
                alt=""
                aspectRatio="118 / 15"
                containerClassName={styles.image}
              />
            </Link>
            <Link
              href="https://shop.basefood.co.jp/privacy"
              target="_blank"
              className={styles.linkContents}
            >
              <Image
                src="https://asset.basefood.co.jp/lp/diet_03/footer_btn_2.png"
                alt=""
                aspectRatio="118 / 15"
                containerClassName={styles.image}
              />
            </Link>
            <Link href="https://basefood.co.jp/law" target="_blank" className={styles.linkContents}>
              <Image
                src="https://asset.basefood.co.jp/lp/diet_03/footer_btn_3.png"
                alt=""
                aspectRatio="118 / 15"
                containerClassName={styles.image}
              />
            </Link>
          </div>
        </div>
      </div>
      <ScrollButton pattern={"green"} />
    </div>
  );
}
