import { useEffect } from "react";

interface ScrollObserverOptions {
  topVal?: number;
  rightVal?: number;
  bottomVal?: number;
  leftVal?: number;
  thresholdVal?: number;
  windowVal?: number;
  repeat?: boolean;
  Start: (target: Element) => void;
  End: (target: Element) => void;
}

export const useScrollObserver = (
  selector: string,
  {
    topVal = 100,
    rightVal = 0,
    bottomVal = -10,
    leftVal = 0,
    thresholdVal = 0.5,
    windowVal = 0.5,
    repeat = true,
    Start,
    End,
  }: ScrollObserverOptions
) => {
  useEffect(() => {
    const boxes = document.querySelectorAll(selector);
    const options: IntersectionObserverInit = {
      rootMargin: `${topVal}% ${rightVal}% ${bottomVal}% ${leftVal}%`,
      threshold: thresholdVal,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > windowVal) {
          Start(entry.target);
          if (!repeat) {
            observer.unobserve(entry.target);
          }
        } else {
          End(entry.target);
        }
      });
    }, options);

    boxes.forEach((box) => observer.observe(box));

    return () => observer.disconnect();
  }, [selector, topVal, rightVal, bottomVal, leftVal, thresholdVal, windowVal, repeat, Start, End]);
};
