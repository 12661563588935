"use client";

import { useState } from "react";

import clsx from "clsx";

import { Image } from "@/components/displays";

import styles from "./LineupSection.module.scss";

export function LineupSection(): React.ReactNode {
  const [showMore, setShowMore] = useState(false);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="text__center mg__bottom__l">
            <h2 className="text__xl text__bold">ラインナップ</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className={styles.flavorLineup}>
            <dl>
              <dt>
                <Image
                  src="https://asset.basefood.co.jp/images/pkg/thumb_bb_chocolate_v1_12_omote.png"
                  alt="BASE BREAD チョコレート"
                  aspectRatio="1 / 1"
                  containerClassName={clsx(styles.image, styles.fullImages)}
                />
              </dt>
              <dd>
                <p className="text__s text__white text__bold text__center__pc">
                  BASE BREAD
                  <sup className="sup">
                    <i className="far fa-registered fa-fw" />
                  </sup>
                </p>
                <p className="text__m text__bold text__center__pc">チョコレート</p>
                <p className="text__m mg__top__s">
                  しっとりチョコが折り込まれた、やわもち食感のマーブルチョコパン。
                </p>
              </dd>
            </dl>
            <dl>
              <dt>
                <Image
                  src="https://asset.basefood.co.jp/images/pkg/thumb_bb_maple_v1_6_omote.png"
                  alt="BASE BREAD メープル"
                  aspectRatio="1 / 1"
                  containerClassName={clsx(styles.image, styles.fullImages)}
                />
              </dt>
              <dd>
                <p className="text__s text__white text__bold text__center__pc">
                  BASE BREAD
                  <sup className="sup">
                    <i className="far fa-registered fa-fw" />
                  </sup>
                </p>
                <p className="text__m text__bold text__center__pc">メープル</p>
                <p className="text__m mg__top__s">
                  まろやかな甘みにほっとする、スティックタイプのメープルパン。
                </p>
              </dd>
            </dl>
            <dl>
              <dt>
                <Image
                  src="https://asset.basefood.co.jp/images/pkg/thumb_bb_cinnamon_v1_6_omote.png"
                  alt="BASE BREAD シナモン"
                  aspectRatio="1 / 1"
                  containerClassName={clsx(styles.image, styles.fullImages)}
                />
              </dt>
              <dd>
                <p className="text__s text__white text__bold text__center__pc">
                  BASE BREAD
                  <sup className="sup">
                    <i className="far fa-registered fa-fw" />
                  </sup>
                </p>
                <p className="text__m text__bold text__center__pc">シナモン</p>
                <p className="text__m mg__top__s">
                  本格シナモンの豊かな香りがたのしめるシナモン味。
                </p>
              </dd>
            </dl>
            <dl>
              <dt>
                <Image
                  src="https://asset.basefood.co.jp/images/pkg/thumb_bb_rich_v1_4_omote.png"
                  alt="BASE BREAD リッチ"
                  aspectRatio="1 / 1"
                  containerClassName={clsx(styles.image, styles.fullImages)}
                />
              </dt>
              <dd>
                <p className="text__s text__white text__bold text__center__pc">
                  BASE BREAD
                  <sup className="sup">
                    <i className="far fa-registered fa-fw" />
                  </sup>
                </p>
                <p className="text__m text__bold text__center__pc">リッチ</p>
                <p className="text__m mg__top__s">
                  ふっくらとした生地にまろやかな甘み広がる、芳醇なあじわいのロールパン。
                </p>
              </dd>
            </dl>
            <dl>
              <dt>
                <Image
                  src="https://asset.basefood.co.jp/images/pkg/thumb_bb_slice_raisin_v1_3_omote.png"
                  alt="BASE BREAD ミニ食パン・レーズン"
                  aspectRatio="1 / 1"
                  containerClassName={clsx(styles.image, styles.fullImages)}
                />
              </dt>
              <dd>
                <p className="text__s text__white text__bold text__center__pc">
                  BASE BREAD
                  <sup className="sup">
                    <i className="far fa-registered fa-fw" />
                  </sup>
                </p>
                <p className="text__m text__bold text__center__pc">ミニ食パン・レーズン</p>
                <p className="text__m mg__top__s">
                  みずみずしいレーズンの甘みがじゅわっと広がる、サクもち食感のミニ食パン。
                </p>
              </dd>
            </dl>
            <dl>
              <dt>
                <Image
                  src="https://asset.basefood.co.jp/images/pkg/thumb_bb_curry_v1_6_omote.png"
                  alt="BASE BREAD カレー"
                  aspectRatio="1 / 1"
                  containerClassName={clsx(styles.image, styles.fullImages)}
                />
              </dt>
              <dd>
                <p className="text__s text__white text__bold text__center__pc">
                  BASE BREAD
                  <sup className="sup">
                    <i className="far fa-registered fa-fw" />
                  </sup>
                </p>
                <p className="text__m text__bold text__center__pc">カレー</p>
                <p className="text__m mg__top__s">
                  揚げずに焼いて仕上げた、やわもち食感の濃厚カレーパン。
                </p>
              </dd>
            </dl>
            <dl>
              <dt>
                <Image
                  src="https://asset.basefood.co.jp/images/pkg/thumb_bb_slice_plain_v1_5_omote.png"
                  alt="BASE BREAD ミニ食パン・プレーン"
                  aspectRatio="1 / 1"
                  containerClassName={clsx(styles.image, styles.fullImages)}
                />
              </dt>
              <dd>
                <p className="text__s text__white text__bold text__center__pc">
                  BASE BREAD
                  <sup className="sup">
                    <i className="far fa-registered fa-fw" />
                  </sup>
                </p>
                <p className="text__m text__bold text__center__pc">ミニ食パン・プレーン</p>
                <p className="text__m mg__top__s">やさしい甘みでアレンジ楽しい全粒粉ミニ食パン。</p>
              </dd>
            </dl>
            <div />
            <dl>
              <dt>
                <Image
                  src="https://asset.basefood.co.jp/images/pkg/thumb_bc_matcha_v1_2_omote.png"
                  alt="BASE Cookies 抹茶"
                  aspectRatio="1 / 1"
                  containerClassName={clsx(styles.image, styles.fullImages)}
                />
              </dt>
              <dd>
                <p className="text__s text__white text__bold text__center__pc">
                  BASE Cookies
                  <sup className="sup">
                    <i className="far fa-registered fa-fw" />
                  </sup>
                </p>
                <p className="text__m text__bold text__center__pc">抹茶</p>
                <p className="text__m mg__top__s">
                  上質な抹茶にコクのある黒糖をあわせた、深みある大人の味わい。
                </p>
              </dd>
            </dl>
            <dl>
              <dt>
                <Image
                  src="https://asset.basefood.co.jp/images/pkg/thumb_bc_coconut_v1_2_omote.png"
                  alt="BASE Cookies ココナッツ"
                  aspectRatio="1 / 1"
                  containerClassName={clsx(styles.image, styles.fullImages)}
                />
              </dt>
              <dd>
                <p className="text__s text__white text__bold text__center__pc">
                  BASE Cookies
                  <sup className="sup">
                    <i className="far fa-registered fa-fw" />
                  </sup>
                </p>
                <p className="text__m text__bold text__center__pc">ココナッツ</p>
                <p className="text__m mg__top__s">
                  粗挽きココナッツの風味と香りがひとくちごとに広がる、ほんのり甘いクッキー。
                </p>
              </dd>
            </dl>
            <dl>
              <dt>
                <Image
                  src="https://asset.basefood.co.jp/images/pkg/thumb_bc_sweetpotato_v1_2_omote.png"
                  alt="BASE Cookies さつまいも"
                  aspectRatio="1 / 1"
                  containerClassName={clsx(styles.image, styles.fullImages)}
                />
              </dt>
              <dd>
                <p className="text__s text__white text__bold text__center__pc">
                  BASE Cookies
                  <sup className="sup">
                    <i className="far fa-registered fa-fw" />
                  </sup>
                </p>
                <p className="text__m text__bold text__center__pc">さつまいも</p>
                <p className="text__m mg__top__s">
                  紫さつまいもを使用、子どもから大人までたのしめる自然でやさしい甘み。
                </p>
              </dd>
            </dl>
            <div />
          </div>
          {showMore && (
            <div className={styles.flavorLineup}>
              <dl>
                <dt>
                  <Image
                    src="https://asset.basefood.co.jp/images/pkg/thumb_bb_plain_v3_11_omote.png"
                    alt="BASE BREAD プレーン"
                    aspectRatio="1 / 1"
                    containerClassName={clsx(styles.image, styles.fullImages)}
                  />
                </dt>
                <dd>
                  <p className="text__s text__white text__bold text__center__pc">
                    BASE BREAD
                    <sup className="sup">
                      <i className="far fa-registered fa-fw" />
                    </sup>
                  </p>
                  <p className="text__m text__bold text__center__pc">プレーン</p>
                  <p className="text__m mg__top__s">
                    全粒粉のやさしい甘み、やわもち食感の丸パン。ハンバーガーなどアレンジ自在。
                  </p>
                </dd>
              </dl>
              <dl>
                <dt>
                  <Image
                    src="https://asset.basefood.co.jp/images/pkg/thumb_bc_cocoa_v2_1_omote.png"
                    alt="BASE Cookies ココア"
                    aspectRatio="1 / 1"
                    containerClassName={clsx(styles.image, styles.fullImages)}
                  />
                </dt>
                <dd>
                  <p className="text__s text__white text__bold text__center__pc">
                    BASE Cookies
                    <sup className="sup">
                      <i className="far fa-registered fa-fw" />
                    </sup>
                  </p>
                  <p className="text__m text__bold text__center__pc">ココア</p>
                  <p className="text__m mg__top__s">
                    ココアがふんわり香る、落ち着く大人のクッキー。
                  </p>
                </dd>
              </dl>
              <dl>
                <dt>
                  <Image
                    src="https://asset.basefood.co.jp/images/pkg/thumb_bc_earlgrey_v1_7_omote.png"
                    alt="BASE Cookies アールグレイ"
                    aspectRatio="1 / 1"
                    containerClassName={clsx(styles.image, styles.fullImages)}
                  />
                </dt>
                <dd>
                  <p className="text__s text__white text__bold text__center__pc">
                    BASE Cookies
                    <sup className="sup">
                      <i className="far fa-registered fa-fw" />
                    </sup>
                  </p>
                  <p className="text__m text__bold text__center__pc">アールグレイ</p>
                  <p className="text__m mg__top__s">
                    アールグレイの香り引き立つ、気分ほぐれるクッキー。
                  </p>
                </dd>
              </dl>
              <dl>
                <dt>
                  <Image
                    src="https://asset.basefood.co.jp/images/pkg/thumb_bp_asian_v6_7_omote.png"
                    alt="BASE PASTA アジアン"
                    aspectRatio="1 / 1"
                    containerClassName={clsx(styles.image, styles.fullImages)}
                  />
                </dt>
                <dd>
                  <p className="text__s text__white text__bold text__center__pc">
                    BASE PASTA
                    <sup className="sup">
                      <i className="far fa-registered fa-fw" />
                    </sup>
                  </p>
                  <p className="text__m text__bold text__center__pc">アジアン</p>
                  <p className="text__m mg__top__s">
                    ペペロンチーノから焼きそばまで、アレンジ自在な細麺。
                  </p>
                </dd>
              </dl>
              <div />
              <dl>
                <dt>
                  <Image
                    src="https://asset.basefood.co.jp/images/pkg/thumb_bp_fettuccine_v6_7_omote.png"
                    alt="BASE PASTA フェットチーネ"
                    aspectRatio="1 / 1"
                    containerClassName={clsx(styles.image, styles.fullImages)}
                  />
                </dt>
                <dd>
                  <p className="text__s text__white text__bold text__center__pc">
                    BASE PASTA
                    <sup className="sup">
                      <i className="far fa-registered fa-fw" />
                    </sup>
                  </p>
                  <p className="text__m text__bold text__center__pc">フェットチーネ</p>
                  <p className="text__m mg__top__s">
                    クリーム系など濃厚ソースにぴったり、もちもち食感の平打ち麺。
                  </p>
                </dd>
              </dl>
              <dl>
                <dt>
                  <Image
                    src="https://asset.basefood.co.jp/images/pkg/thumb_bp_frozen_bolognese_v1_1.png"
                    alt="BASE PASTA ボロネーゼ"
                    aspectRatio="1 / 1"
                    containerClassName={clsx(styles.image, styles.fullImages)}
                  />
                  <span
                    className={clsx(
                      styles.tag,
                      "bg__darkblue",
                      "inline",
                      styles.square,
                      styles.mini,
                      "text__white"
                    )}
                  >
                    冷凍
                  </span>
                </dt>
                <dd>
                  <p className="text__s text__white text__bold text__center__pc">
                    BASE PASTA
                    <sup className="sup">
                      <i className="far fa-registered fa-fw" />
                    </sup>
                  </p>
                  <p className="text__m text__bold text__center__pc">ボロネーゼ</p>
                  <p className="text__m mg__top__s">
                    肉感ゴロゴロで本格的なソースと調和した絶品パスタ。
                  </p>
                </dd>
              </dl>
              <dl>
                <dt>
                  <Image
                    src="https://asset.basefood.co.jp/images/pkg/thumb_bp_frozen_tarako_v1_1.png"
                    alt="BASE PASTA たらこ"
                    aspectRatio="1 / 1"
                    containerClassName={clsx(styles.image, styles.fullImages)}
                  />
                  <span
                    className={clsx(
                      styles.tag,
                      "bg__darkblue",
                      "inline",
                      styles.square,
                      styles.mini,
                      "text__white"
                    )}
                  >
                    冷凍
                  </span>
                </dt>
                <dd>
                  <p className="text__s text__white text__bold text__center__pc">
                    BASE PASTA
                    <sup className="sup">
                      <i className="far fa-registered fa-fw" />
                    </sup>
                  </p>
                  <p className="text__m text__bold text__center__pc">たらこ</p>
                  <p className="text__m mg__top__s">
                    “生”たらこと“焼き“たらこの2種のたらこが入ったパスタ。
                  </p>
                </dd>
              </dl>
              <dl>
                <dt>
                  <Image
                    src="https://asset.basefood.co.jp/images/pkg/thumb_bp_frozen_mushroom_v1_1.png"
                    alt="BASE PASTA きのこクリーム"
                    aspectRatio="1 / 1"
                    containerClassName={clsx(styles.image, styles.fullImages)}
                  />
                  <span
                    className={clsx(
                      styles.tag,
                      "bg__darkblue",
                      "inline",
                      styles.square,
                      styles.mini,
                      "text__white"
                    )}
                  >
                    冷凍
                  </span>
                </dt>
                <dd>
                  <p className="text__s text__white text__bold text__center__pc">
                    BASE PASTA
                    <sup className="sup">
                      <i className="far fa-registered fa-fw" />
                    </sup>
                  </p>
                  <p className="text__m text__bold text__center__pc">きのこクリーム</p>
                  <p className="text__m mg__top__s">
                    3種のきのことホワイトソースのクリーミーなパスタ。
                  </p>
                </dd>
              </dl>
            </div>
          )}
          <div className="col-12">
            <div className="text__center mg__top__l">
              {showMore ? (
                <button
                  onClick={() => setShowMore(false)}
                  className="btn btn__l gray inline round text__m text__black"
                >
                  閉じる
                </button>
              ) : (
                <button
                  onClick={() => setShowMore(true)}
                  className="btn btn__l gray inline round text__m text__black"
                >
                  もっとみる
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
